import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import SEO from '../components/seo'
import './index.css'

const IndexPage = () => {
  return (
    <>
    <SEO title="Home" />

    <Helmet>
      {/* <link rel="preload" as="style" href="https://unpkg.com/tachyons/css/tachyons.min.css" onload="this.onload=null; this.rel='stylesheet'"/> */}
      <link rel="preload" as="style" href="https://use.typekit.net/rba4url.css" onload="this.onload=null; this.rel='stylesheet'"/>
    </Helmet>

    <div className="p-4 max-w-7xl mx-auto">
      <header className="float-left w-full w-1/2 p-4 lg:p-8 mb-4 lg:mb-16">
        <h2 className="text-2xl mt-0">
          EURO TOOL WORKS
        </h2>
      </header>
      <section className="float-left w-full">
        <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/4 p-4 lg:p-8 lg:pt-0 float-left">
          <p className="text-base sm:max-w-100 lg:max-w-md mb-4">
            We work to bring quality European brands to the US market. We're interested in quality and products that speak for themselves rather than over-the-top marketing and empty promises.
          </p>
          <p className="text-base max-w-md mb-4">
            If you think we'd be a good fit for your brand please <a href="mailto:support@uniorusa.com">contact us</a>.
          </p>
        </div>
        <div className="sm:w-full md:w-1/2 lg:w-1/2 xl:w-3/4 md:float-left">
          <div className="w-full xl:w-1/3 p-4 lg:p-8 lg:pt-0 text-center xl:float-left">
            <a href="http://uniorusa.com">
              <StaticImage src="../images/logo-unior.png" brand="Unior Bike Tools" />
            </a>
          </div>
          <div className="w-full xl:w-1/3 p-4 lg:p-8 lg:pt-0 text-center xl:float-left">
            <a href="https://juicelubes.co.uk">
              <StaticImage src="../images/logo-juicelubes.png" brand="Juice Lubes" />
            </a>
          </div>
          <div className="w-full xl:w-1/3 p-4 lg:p-8 lg:pt-0 text-center xl:float-left">
            <a href="https://sinter.si">
              <StaticImage src="../images/logo-sinter.png" brand="Sinter Brakes" />
            </a>
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default IndexPage
